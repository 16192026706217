import { Link } from 'gatsby';
import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import Card from '../Shared/Card';
import H3 from '../Shared/H3';
import Image from '../Shared/Image';
import Row from '../Shared/Row';
import SectionWhite from '../Shared/SectionWhite';

type Props = {
  posts: any[];
  currentPage: number;
  pagination: JSX.Element;
};

const CaseStudyArchiveBody: FC<Props> = ({
  posts,
  currentPage,
  pagination,
}) => {
  const bodyRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (currentPage > 1) {
      bodyRef.current.scrollIntoView();
    }
  }, [currentPage]);

  return (
    <div ref={bodyRef}>
      <SectionWhite isOffWhite>
        <CaseStudyArchiveBodyRow>
          {posts.map((post, index) => {
            const { title, image } = post.hero_case_study.heroCaseStudy;

            let types = post.casestudyTypes.nodes.map((node) => node.name);

            return (
              <Block key={index} mdWidth={1 / 2}>
                <Link to={post.uri}>
                  <Card.Card>
                    <Card.CardImage>
                      <Card.CardImageBackground>
                        <Image image={image} />
                      </Card.CardImageBackground>
                    </Card.CardImage>
                    <Card.CardText>
                      <CardTextSmall>{types.join(' | ')}</CardTextSmall>
                      <H3 isColorInverted>{title}</H3>
                    </Card.CardText>
                  </Card.Card>
                </Link>
              </Block>
            );
          })}
        </CaseStudyArchiveBodyRow>
        {pagination}
      </SectionWhite>
    </div>
  );
};

export default CaseStudyArchiveBody;

const CaseStudyArchiveBodyRow = styled(Row)`
  margin-bottom: -2rem;

  ${variables.breakpoints.md} {
    margin-bottom: -2.5rem;
  }

  ${variables.breakpoints.xl} {
    margin-bottom: -4rem;
  }
`;

const CardTextSmall = styled.p`
  color: ${variables.css.color.grey};
  margin-bottom: 1.5rem;
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize.md};
  font-weight: ${variables.css.fontWeight.regular};
`;
