import { graphql } from 'gatsby';
import React from 'react';
import FlexibleContent from '../components/FlexibleContent';
import FlexibleHero from '../components/FlexibleHero';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Pagination from '../components/Shared/Pagination';
import CaseStudyArchiveBody from '../components/Static/CaseStudyArchiveBody';

const CaseStudyArchive = ({ data, location, pageContext }) => {
  const {
    currentPage,
    numPages,
    seo,
    flexibleHero,
    flexibleContent,
    hreflang,
  } = pageContext;

  return (
    <Layout>
      <SEO pathname={location.pathname} seo={seo} hreflang={hreflang} />
      <FlexibleHero
        breadcrumbs={seo.breadcrumbs}
        location={location}
        components={flexibleHero}
      />
      <CaseStudyArchiveBody
        posts={data.allWpCaseStudy.nodes}
        currentPage={currentPage}
        pagination={
          <Pagination
            location={location}
            currentPage={currentPage}
            numPages={numPages}
          />
        }
      />
      <FlexibleContent prefix='page' components={flexibleContent} />
    </Layout>
  );
};

export default CaseStudyArchive;

export const query = graphql`
  query($skip: Int!, $limit: Int!, $filter: WpCase_studyFilterInput) {
    allWpCaseStudy(skip: $skip, limit: $limit, filter: $filter) {
      nodes {
        uri
        hero_case_study {
          heroCaseStudy {
            title
            image {
              altText
              localFile {
                childImageSharp {
                  # BETA: https://github.com/gatsbyjs/gatsby/discussions/27950
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 768
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
        casestudyTypes {
          nodes {
            name
          }
        }
      }
    }
  }
`;
