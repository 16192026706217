import styled from 'styled-components';
import variables from '../../helpers/variables';
import ImageBackground from './ImageBackground';

const Card = styled.div`
  background-color: ${variables.css.color.white};
  margin-bottom: 2rem;

  ${variables.breakpoints.md} {
    margin-bottom: 2.5rem;
  }

  ${variables.breakpoints.xl} {
    margin-bottom: 4rem;
  }

  &:hover ${ImageBackground} {
    transform: scale(1.1);
  }
`;

const CardImage = styled.div`
  background-color: ${variables.css.color.white};
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
`;

const CardImageBackground = styled(ImageBackground)`
  transition: transform 0.3s;
`;

const CardText = styled.div`
  padding: 1.5rem;
`;

export default { Card, CardImage, CardImageBackground, CardText };
