import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import Block from './Block';
import Row from './Row';
import Section from './Section';

type Props = {
  location: any;
  currentPage: number;
  numPages: number;
  backgroundColour?: string;
};

const Pagination: FC<Props> = ({
  location,
  currentPage,
  numPages,
  backgroundColour,
}) => {
  const indexToStrip = location.pathname.indexOf('page');
  const pathname =
    indexToStrip === -1
      ? location.pathname
      : location.pathname.substring(0, indexToStrip);

  const pages = [
    ...new Set([
      1,
      Math.max(1, currentPage - 1),
      currentPage,
      Math.min(numPages, currentPage + 1),
      numPages,
    ]),
  ];

  let links = [];
  for (let i = 0; i < pages.length; i++) {
    links.push({
      title: pages[i],
      url: pages[i] === 1 ? `${pathname}` : `${pathname}page/${pages[i]}/`,
    });

    if (pages[i + 1] && pages[i + 1] - pages[i] > 1) {
      links.push(undefined);
    }
  }

  return (
    links.length > 1 && (
      <PaginationSection backgroundColour={backgroundColour}>
        <Row>
          <Block>
            <List>
              {currentPage > 1 && (
                <ItemPrev>
                  <Link
                    to={
                      currentPage - 1 === 1
                        ? `${pathname}`
                        : `${pathname}page/${currentPage - 1}/`
                    }
                  >
                    PREV
                  </Link>
                </ItemPrev>
              )}
              {links.map((link, index) => (
                <ItemList
                  key={index}
                  isBorderless={index === links.length - 1}
                  isCurrent={link?.title === currentPage}
                >
                  {link ? <Link to={link.url}>{link.title}</Link> : '...'}
                </ItemList>
              ))}
              {currentPage < numPages && (
                <ItemNext>
                  <Link to={`${pathname}page/${currentPage + 1}/`}>NEXT</Link>
                </ItemNext>
              )}
            </List>
          </Block>
        </Row>
      </PaginationSection>
    )
  );
};

export default Pagination;

interface PaginationSection {
  backgroundColour?: string;
}

const PaginationSection = styled(Section)<PaginationSection>`
  background-color: ${(props) =>
    props.backgroundColour || variables.css.color.offwhite};
  padding-bottom: 0;
`;

const List = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Item = styled.li`
  color: ${variables.css.color.grey};
  display: inline-block;
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize.lg};
  font-weight: ${variables.css.fontWeight.regular};
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;

  & > a {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
`;

const ItemPrev = styled(Item)`
  padding-left: 1rem;
  padding-right: 3rem;
`;

type ItemListProps = {
  isBorderless: boolean;
  isCurrent: boolean;
};

const ItemList = styled(Item)<ItemListProps>`
  color: ${(p) =>
    p.isCurrent ? variables.css.color.blue : variables.css.color.grey};
  display: ${(p) => (p.isCurrent ? 'inline-block' : 'none')};
  font-weight: ${(p) =>
    p.isCurrent
      ? variables.css.fontWeight.black
      : variables.css.fontWeight.regular};
  width: 3rem;

  ${variables.breakpoints.md} {
    border-right: ${(p) =>
      p.isBorderless ? 'none' : `1px solid ${variables.css.color.grey}`};
    display: inline-block;
  }
`;

const ItemNext = styled(Item)`
  padding-left: 3rem;
  padding-right: 1rem;
`;
